import React from "react"
import "./locations-section.scss"
import ProgressBar from "./progress-bar"
import { LocationsSectionProps } from "../../types/components"
import { ProgressiveBarProps } from "../../types/shared-singulars"
import Img from "../shared/img"

const LocationsSection: React.FC<LocationsSectionProps> = ({
  firstTitle,
  secondTitle,
  image,
  graphBars,
}) => {
  const highest = Math.max(...graphBars.map(n => Number(n.number)))

  return (
    <div className="locations-section">
      <div className="locations-section__wrapper container--xl">
        <div className="locations-section__image">
          <h1
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-easing="ease-out"
          >
            {firstTitle}
          </h1>
          <div>
            <Img imgProps={image} delay="250" />
          </div>
        </div>
        <div className="locations-section__graph">
          <h2
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-easing="ease-out"
          >
            {secondTitle}
          </h2>
          <div className="locations-section__graph__items">
            {graphBars.map((item: ProgressiveBarProps, index: number) => (
              <div
                key={index}
                className="locations-section__graph__items__item"
                data-aos="fade-up"
                data-aos-delay={(index + 1) * 100}
                data-aos-easing="ease-out"
              >
                <div className="locations-section__graph__items__item__text half--left">
                  <h4>{item.title}</h4>
                </div>
                <ProgressBar
                  countUpNumber={Number(item.number)}
                  lineWidth={Math.round((Number(item.number)/ highest) * 100)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationsSection
