import React from "react"
import "./grid-container.scss"
import { GridContainerProps } from "../../types/components"

const GridContainer: React.FC<GridContainerProps> = ({ children, parent }) => {
  return (
    <div className={`grid-container ${parent}`}>
      <div className="grid-container__wrapper container--xl">{children}</div>
    </div>
  )
}

export default GridContainer
