import React from "react"
import "./progress-bar.scss"
import CountUp from "react-countup"

type Props = {
  countUpNumber: number
  lineWidth: number
}

const ProgressBar: React.FC<Props> = ({ countUpNumber, lineWidth }) => {
  return (
    <div className="progress-bar half--right">
      <span className="progress-bar__line" style={{ width: `${lineWidth}%` }} />
      <CountUp
        className="progress-bar__number"
        end={countUpNumber}
        duration={4}
        useEasing={true}
      />
    </div>
  )
}

export default ProgressBar
