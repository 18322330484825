import React, { memo } from "react"
import "./count-up-node.scss"
import CountUp from "react-countup"
import { CountUpNodeProps } from "../../types/components"

const CountUpNode: React.FC<CountUpNodeProps> = ({
  index,
  icon,
  description,
  title,
}) => {
  return (
    <div
      className="count-up-node"
      data-aos="fade-up"
      data-aos-delay={index && index * 100}
      data-aos-easing="ease-out"
    >
      {typeof title === "number" ? (
        <div className="count-up-node__item">
          <img
            className="count-up-node__item__icon"
            alt={icon.altText}
            srcSet={icon.srcSet ? icon.srcSet : icon.sourceUrl}
            sizes={icon.sizes}
          />
          <CountUp end={title} duration={5} useEasing={true} />
        </div>
      ) : (
        <div className="count-up-node__item">
          <img
            className="count-up-node__item__icon"
            alt={icon.altText}
            srcSet={icon.srcSet ? icon.srcSet : icon.sourceUrl}
            sizes={icon.sizes}
          />
          <span>{title}</span>
        </div>
      )}
      <h6>{description}</h6>
    </div>
  )
}

export default memo(CountUpNode)
