import React from "react"
import { graphql, PageProps } from "gatsby"
import PageLayout from "../layouts/page-layout"
import { HomePageProps } from "../types/pages"
import PageBanner from "../components/shared/page-banner"
import { CircleArrow } from "../components/elements/svg-elements"
import AnimatedStatistics from "../components/home/animated-statistics"
import GridContainer from "../components/shared/grid-container"
import GridItem from "../components/shared/grid-item"
import Slider from "../components/shared/slider"
import PhilosophySection from "../components/home/philosophy-section"
import LocationsSection from "../components/home/locations-section"
import { FetchedGridItemProps } from "../types/shared-singulars"
import PageLink from "../components/shared/page-link"
import Img from "../components/shared/img"
import { nestedArray } from "../utils/helpers"

const IndexPage: React.FC<PageProps<HomePageProps>> = ({ data }) => {
  const {
    mainBanner,
    mainTitle,
    whatWeDo,
    ourServices,
    ourServicesPages,
    team,
    allTeamMembers,
    teamMembersSelector,
    aboutUs,
    consultingPhilosophy,
    ourResponsibility,
    aboutUsStaticPost,
    caseStudies,
    whereWeWork,
    whatWeWorkWith,
    articles,
  } = data?.PAGE_CONTENT?.homeOptions

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <PageBanner>
        <div className="page-banner__image">
          <Img imgProps={mainBanner} />
        </div>
        <h1
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-easing="ease-out"
          className="page-banner__title "
        >
          {mainTitle}
        </h1>
      </PageBanner>
      <AnimatedStatistics elements={whatWeDo} />
      <GridContainer parent="home">
        <div
          className="grid-container__header"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-easing="ease-out"
        >
          <h1>{ourServices}</h1>
        </div>
        <div className="grid-container__items">
          {ourServicesPages &&
            nestedArray(ourServicesPages).map(
              (group: FetchedGridItemProps[], groupIndex: number) =>
                group.map((item: FetchedGridItemProps, index: number) => (
                  <GridItem
                    key={index}
                    index={groupIndex + 1}
                    featuredImage={item?.featuredImage}
                    uri={item.uri}
                    className="grid-item__image-wrapper__image zoom"
                  >
                    <div className="grid-item__title-nodes">
                      <CircleArrow />
                      <h3>{item.title}</h3>
                    </div>
                  </GridItem>
                ))
            )}
        </div>
      </GridContainer>
      {teamMembersSelector && (
        <Slider parent="team-slider" slides={teamMembersSelector}>
          <h1>{team}</h1>
          <PageLink templateName="Team">{allTeamMembers}</PageLink>
        </Slider>
      )}
      <PhilosophySection
        aboutUs={aboutUs}
        consultingPhilosophy={consultingPhilosophy}
        ourResponsibility={ourResponsibility}
        aboutUsStaticPost={aboutUsStaticPost}
      />
      <GridContainer parent="home">
        <div className="grid-container__header">
          <h1
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-easing="ease-out"
          >
            {caseStudies.title}
          </h1>
          <PageLink templateName="Cases">{caseStudies.allCases}</PageLink>
        </div>
        <div className="grid-container__items">
          {caseStudies?.casePostSelector &&
            nestedArray(caseStudies.casePostSelector).map(
              (group: FetchedGridItemProps[], groupIndex: number) =>
                group.map((item: FetchedGridItemProps, index: number) => (
                  <GridItem
                    key={index}
                    index={groupIndex + 1}
                    featuredImage={item?.featuredImage}
                    uri={item.uri}
                    tags={item.singleCasesOptions?.tags?.split(",")}
                  >
                    {item?.singleArticleOptions?.authorSelector && (
                      <div className="grid-item__authors">
                        {item?.singleArticleOptions?.authorSelector?.map(
                          (item: any, index: number) => (
                            <span
                              key={index}
                              className="grid-item__authors__tag"
                            >
                              {item?.title}{" "}
                            </span>
                          )
                        )}
                      </div>
                    )}
                    <div className="grid-item__title-nodes">
                      <h3>{item.title}</h3>
                    </div>
                  </GridItem>
                ))
            )}
        </div>
      </GridContainer>
      <LocationsSection
        firstTitle={whereWeWork.title}
        secondTitle={whatWeWorkWith.title}
        image={whereWeWork.locationsImage}
        graphBars={whatWeWorkWith.whatWeWorkWith}
      />
      <Slider parent="article-slider" slides={articles.articlesSelector}>
        <h1 data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out">
          {articles.title}
        </h1>
        <PageLink templateName="Como-moves">{articles.allArticles}</PageLink>
      </Slider>
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      homeOptions {
        mainBanner {
          sizes
          srcSet
          sourceUrl
          altText
        }
        mainTitle
        whatWeDo {
          description
          title
          icon {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
        ourServices
        ourServicesPages {
          ... on WpPage {
            id
            uri
            title
            featuredImage {
              node {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
          }
        }
        team
        allTeamMembers
        teamMembersSelector {
          ... on WpTeam {
            id
            uri
            title
            featuredImage {
              node {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
            singleTeam {
              position
            }
          }
        }
        aboutUs
        consultingPhilosophy {
          title
          url
        }
        ourResponsibility
        aboutUsStaticPost {
          content
          title
        }
        caseStudies {
          allCases
          title
          casePostSelector {
            ... on WpCase {
              id
              uri
              title
              featuredImage {
                node {
                  altText
                  sizes
                  sourceUrl
                  srcSet
                }
              }
              singleCasesOptions {
                tags
              }
            }
          }
        }
        whereWeWork {
          title
          locationsImage {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
        whatWeWorkWith {
          title
          whatWeWorkWith {
            number
            title
          }
        }
        articles {
          title
          allArticles
          articlesSelector {
            ... on WpArticle {
              id
              uri
              title
              featuredImage {
                node {
                  altText
                  sizes
                  sourceUrl
                  srcSet
                }
              }
              singleArticleOptions {
                authorSelector {
                  ... on WpTeam {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
