import React, { memo } from "react"
import "./grid-item.scss"
import { Link } from "gatsby"
import { GridItemProps } from "../../types/components"
import Img from "../shared/img"
import { useStaticQuery, graphql } from "gatsby"

const GridItem: React.FC<GridItemProps> = ({
  index,
  children,
  featuredImage,
  uri,
  tags,
  className,
}) => {
  const { IMAGE } = useStaticQuery(graphql`
    query {
      IMAGE: file(relativePath: { eq: "missing-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Link
      to={uri}
      className="grid-item"
      data-aos="fade-up"
      data-aos-delay={index && index * 150}
      data-aos-easing="ease-out"
    >
      <div className="grid-item__image-wrapper">
        <div
          className="grid-item__image-wrapper__image"
          style={{
            backgroundImage: `url(${
              featuredImage
                ? featuredImage.node.sourceUrl
                : IMAGE.childImageSharp.fluid.src
            })`,
          }}
        />
        {tags && (
          <div className="grid-item__image-wrapper__tags">
            {tags.map((tag: string, index: number) => (
              <span key={index}>{tag}</span>
            ))}
          </div>
        )}
      </div>
      {children}
    </Link>
  )
}

export default memo(GridItem)
