import React from "react"
import "./animated-statistics.scss"
import CountUpNode from "../shared/count-up-node"
import {
  AnimatedStatisticsProps,
  CountUpNodeProps,
} from "../../types/components"

const AnimatedStatistics: React.FC<AnimatedStatisticsProps> = ({
  elements,
}) => {
  const progressiveElements = [
    {
      description: elements[0].description,
      title: Number(elements[0].title),
      icon: elements[0].icon,
    },
    {
      description: elements[1].description,
      title: Number(elements[1].title),
      icon: elements[1].icon,
    },
    {
      description: elements[2].description,
      title: Number(elements[2].title),
      icon: elements[2].icon,
    },
  ]

  return (
    <div className="animated-statistics">
      <div className="animated-statistics__wrapper container--xl">
        {progressiveElements.map((item: CountUpNodeProps, index: number) => (
          <div key={index} className="animated-statistics__item">
            <CountUpNode
              index={index + 1}
              icon={item.icon}
              description={item.description}
              title={item.title}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AnimatedStatistics
