import React, { memo } from "react"
import "./slider-item.scss"
import { Link } from "gatsby"
import { SliderItemProps } from "../../types/components"
import Img from "./img"
import { AuthorProps } from "../../types/shared-singulars"
import { CircleArrow } from "../elements/svg-elements"

const SliderItem: React.FC<SliderItemProps> = ({
  parent,
  index,
  uri,
  imgProps,
  authors,
  title,
  memberTag,
  children,
}) => {
  function classGenerator() {
    switch (parent) {
      case "team-slider":
        return "slider-item slider-item__team-slider"
      case "article-slider":
        return "slider-item slider-item__article-slider"
      case "team-grid":
        return "slider-item slider-item__team-grid"
      case "accordion":
        return `slider-item slider-item__accordion`
      default:
        return
    }
  }

  const Container = ({ children, parent }: any) => {
    if (uri) {
      return (
        <>
          {parent === "accordion" ? (
            <a
              href={uri}
              className={classGenerator()}
              data-aos={index && "fade-up"}
              data-aos-delay={index && index * 100}
              data-aos-easing={index && "ease-out"}
            >
              {children}
            </a>
          ) : (
            <Link
              to={uri}
              className={classGenerator()}
              data-aos={index && "fade-up"}
              data-aos-delay={index && index * 100}
              data-aos-easing={index && "ease-out"}
            >
              {children}
            </Link>
          )}
        </>
      )
    } else {
      return (
        <div
          className={classGenerator()}
          data-aos={index && "fade-up"}
          data-aos-delay={index && index * 100}
          data-aos-easing={index && "ease-out"}
        >
          {children}
        </div>
      )
    }
  }

  return (
    <Container parent={parent}>
      <div
        className={
          imgProps
            ? "slider-item__image-wrapper"
            : "slider-item__image-wrapper limited-size"
        }
      >
        <Img
          imgProps={imgProps}
          className="slider-item__image-wrapper__image"
        />
      </div>
      <div className="slider-item__text-nodes">
        {authors ? (
          authors?.map((tag: AuthorProps, index: number) => (
            <span key={index}>{tag.title}</span>
          ))
        ) : (
          <></>
        )}
        <h3>{title}</h3>
        {memberTag && <span>{memberTag}</span>}
        {parent !== "article-slider" && <CircleArrow />}
      </div>
      {children}
    </Container>
  )
}

export default memo(SliderItem)
