import React from "react"
import "./philosophy-section.scss"
import { CircleArrow } from "../elements/svg-elements"
import { PhilosophySectionProps } from "../../types/components"
import PageLink from "../shared/page-link"
import { useLanguage } from "../../hooks/use-language"

const PhilosophySection: React.FC<PhilosophySectionProps> = ({
  aboutUs,
  consultingPhilosophy,
  ourResponsibility,
  aboutUsStaticPost,
}) => {
  return (
    <div className="philosophy-section">
      <div className="philosophy-section__wrapper container--xl">
        <div
          className="philosophy-section__half-left"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-easing="ease-out"
        >
          <h1>{aboutUs}</h1>
          <PageLink
            templateName="Philosophy"
            className="philosophy-section__half-left__link"
          >
            <CircleArrow /> <span>{consultingPhilosophy?.title}</span>
          </PageLink>
          <PageLink
            templateName="Responsibilities"
            className="philosophy-section__half-left__link"
          >
            <CircleArrow /> <span>{ourResponsibility}</span>
          </PageLink>
        </div>
        <div
          className="philosophy-section__half-right"
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-easing="ease-out"
        >
          <h1>{aboutUsStaticPost.title}</h1>
          <div
            className="philosophy-section__half-right__description"
            dangerouslySetInnerHTML={{ __html: aboutUsStaticPost.content }}
          />
        </div>
      </div>
      <div className="philosophy-section__accordions">
        <h1 className="container--xl">{aboutUs}</h1>
        <h3 className="container--xl">{aboutUsStaticPost.title}</h3>
        <div
          className="container--xl philosophy-section__accordions__description"
          dangerouslySetInnerHTML={{ __html: aboutUsStaticPost.content }}
        />
        <PageLink templateName="Philosophy">
          <div className="philosophy-section__accordions__last-child container--xl">
            <CircleArrow /> {consultingPhilosophy?.title}
          </div>
        </PageLink>
        <PageLink templateName="Responsibilities">
          <div className="philosophy-section__accordions__last-child container--xl">
            <CircleArrow /> {ourResponsibility}
          </div>
        </PageLink>
      </div>
    </div>
  )
}

export default PhilosophySection
